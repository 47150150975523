<template>
  <Member
    title="Dr. Paolo Parise"
    description="Chirurgia Generale e Chirurgia Gastroenterologica"
    text="L’attività clinica, chirurgica e di ricerca del dott. Paolo Parise è da sempre rivolta alle patologie oncologiche funzionali del tratto gastroenterico ed in particolar modo a quelle di esofago e stomaco. Ha lavorato presso diversi Centri di riferimento ad alto volume nazionali per il trattamento delle suddette patologie (Padova, Pisa, Milano) avendo la possibilità di apprendere ed affinare l’utilizzo delle più moderne tecnologie e tecniche operatorie.

Dal 2004 e docente presso i Corsi Residenziali della Scuola Speciale ACOI di Chirurgia dell’Esofago “Mauro Rossi” di Pisa.

Ha fondato e presiede dal 2018 la Società Italiana per lo Studio delle Malattie dell’Esofago (SISME)."
    :formation="[
      'Università degli Studi di Padova',
      'Laurea in Medicina e Chirurgia ',
      'Università degli Studi di Padova',
      'Specializzazione in Chirurgia Generale',
    ]"
    :pub="true"
    publication="È autore di pubblicazioni scientifiche su riviste nazionali ed internazionali e di capitoli di libri"
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";

export default {
  name: "Parise",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
